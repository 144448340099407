document.addEventListener("DOMContentLoaded", function () {
  const sidebar = document.getElementById("sidebar") as HTMLElement;
  const sidebarToggle = document.getElementById("sidebarToggle") as HTMLElement;

  sidebarToggle.addEventListener("click", function () {
    sidebar.classList.toggle("show");
  });

  document.addEventListener("click", function (event: MouseEvent) {
    const isClickInsideSidebar = sidebar.contains(event.target as Node);
    const isClickOnToggle = sidebarToggle.contains(event.target as Node);

    if (
      !isClickInsideSidebar &&
      !isClickOnToggle &&
      sidebar.classList.contains("show")
    ) {
      sidebar.classList.remove("show");
    }
  });
});
