import { postRequest, deleteRequest } from "helpers";

document.addEventListener("DOMContentLoaded", initializeLikeButtons);

function initializeLikeButtons() {
  const likeButton = document.getElementById("like-button");
  const unlikeButton = document.getElementById("unlike-button");

  if (!likeButton || !unlikeButton) {
    return;
  }

  if (!likeButton.dataset.checklistId) {
    throw new Error("No data-checklist-id found on likeButton");
  }

  const checklistId = likeButton.dataset.checklistId;

  likeButton.addEventListener("click", (event: Event) => {
    event.preventDefault();
    postRequest(`/checklists/${checklistId}/like`, {
      checklist_id: checklistId,
    }).then((data) => {
      toggleButtons({
        newLikeCount: data.like_count,
        showLikeButton: false,
        likeButton,
        unlikeButton,
      });
    });
  });

  unlikeButton.addEventListener("click", (event: Event) => {
    event.preventDefault();
    deleteRequest(`/checklists/${checklistId}/like`).then((data) => {
      toggleButtons({
        newLikeCount: data.like_count,
        showLikeButton: true,
        likeButton,
        unlikeButton,
      });
    });
  });
}

type ToggleButtonsArguments = {
  newLikeCount: number;
  showLikeButton: boolean;
  likeButton: HTMLElement;
  unlikeButton: HTMLElement;
};

function toggleButtons({
  newLikeCount = 0,
  showLikeButton,
  likeButton,
  unlikeButton,
}: ToggleButtonsArguments) {
  likeButton.hidden = !showLikeButton;
  unlikeButton.hidden = showLikeButton;
  changeLikeCounter(newLikeCount);
}

function changeLikeCounter(likeCount: number) {
  const list = document.getElementsByClassName("like-count");
  for (const element of list) {
    const resultAsString = likeCount.toString();
    element.innerHTML = resultAsString;
  }
}
