import { getRequest } from "helpers";
import { debounce } from "lodash";

document.addEventListener("DOMContentLoaded", initializeUserSearch);

function initializeUserSearch() {
  const emailOrUsernameInput = document.getElementById(
    "email_or_username",
  ) as HTMLInputElement;
  if (!emailOrUsernameInput) {
    return;
  }

  const debounceProcessChange = debounce(makeRequest, 300);
  emailOrUsernameInput.addEventListener("keyup", debounceProcessChange);

  const usernameSuggestionsList = document.getElementById(
    "username-suggestions",
  ) as HTMLElement;

  function makeRequest() {
    const input = emailOrUsernameInput.value.trim();
    if (input) {
      const resultPromise = getRequest("/api/usernames", { query: input });
      usernameSuggestionsList.innerHTML = "";

      resultPromise.then((usernameSuggestions: Array<string>) => {
        usernameSuggestions.forEach((username) => {
          const option = document.createElement("option");
          option.value = username;
          option.textContent = username;
          usernameSuggestionsList.appendChild(option);
        });
      });
    }
  }
}
